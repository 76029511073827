import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import Layout from "./../components/Layout"
import Seo from "./../components/Seo"
import BlogPostPreview from "./../components/BlogPostPreview/BlogPostPreview"
import blogStyles from "./../styles/blog.module.scss"
import { getDate } from "./../common/utils"

const Blog = () => {
  const articles = useStaticQuery(graphql`
    query BlogQuery {
      allMarkdownRemark(
        filter: { fields: { collectionType: { eq: "blog" } } }
        limit: 13
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            id
            frontmatter {
              category
              image
              title
              category
              description
              date
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `)

  const [main, ...restOfArticles] = articles.allMarkdownRemark.edges
  const restOfArticlesItems = restOfArticles.map(article => {
    return (
      <BlogPostPreview
        data={article.node.frontmatter}
        slug={article.node.fields.slug}
        key={article.node.frontmatter.title}
      />
    )
  })

  return (
    <Layout>
      <Seo title={"Blog | TechPods"} />
      <main>
        <section className={blogStyles.blog}>
          <Link to={`/blog/${main.node.fields.slug}`}>
            <div className={blogStyles.mainBlogPost}>
              <img
                src={main.node.frontmatter.image}
                alt={main.node.frontmatter.title}
              />
              <div className={blogStyles.blogPostInfo}>
                <span>{main.node.frontmatter.category}</span>
                <h1>{main.node.frontmatter.title}</h1>
                <p>{main.node.frontmatter.description}</p>
                <span>{getDate(main.node.frontmatter.date)}</span>
              </div>
            </div>
          </Link>
          <div className={blogStyles.blogPosts}>{restOfArticlesItems}</div>
        </section>
      </main>
    </Layout>
  )
}

export default Blog
