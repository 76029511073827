import React from 'react';
import { Link } from 'gatsby';

import blogPostPreviewStyles from './blog-post-preview.module.scss';
import { getDate } from './../../common/utils';

const BlogPostPreview = ({ data, slug }) => {
  return (
    <Link to={`/blog/${slug}`}>
      <div className={blogPostPreviewStyles.blogPost}>
        <img src={data.image} alt={data.title} />
        <span className={blogPostPreviewStyles.category}>{data.category}</span>
        <h3>{data.title}</h3>
        <span>{getDate(data.date)}</span>
      </div>
    </Link>
  );
};

export default BlogPostPreview;